import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "components/seo"
import NewsSingleMain from "components/newsSingleMain"
import NewsNextPrevPosts from "components/newsNextPrevPosts"

export default ({ data, location }) => {

    const {
        title,
        content,
        seoFields
    } = data.siteFields.page

    const posts = data.siteFields.posts.edges

    let prevPost = []
    let nextPost = []

    posts.forEach(({ node }, index) => {

        if (node.title === title) {

            prevPost = data.siteFields.posts.edges[index - 1]
            nextPost = data.siteFields.posts.edges[index + 1]

        }

    })

    let excerpt = content ? content.replace(/(<([^>]+)>)/ig, "") : ''
    excerpt = excerpt ? excerpt.slice(0, 160) : ''
    if (excerpt.length === 160) {
        excerpt = excerpt + '...'
    }

    return (
        <Layout location={location}>
            <SEO
                title={title}
                description={excerpt}
                seoFields={seoFields}
            />
            <NewsSingleMain data={data} />
            <NewsNextPrevPosts
                prevPost={prevPost}
                nextPost={nextPost}
            />
        </Layout>
    )
}

export const query = graphql`
    query($databaseId: ID!) {
        siteFields {
            page(id: $databaseId, idType: DATABASE_ID) {
                databaseId
                title
                date
                content(format: RENDERED)
                featuredImage {
                    node {
                        altText
                        title(format: RENDERED)
                        mediaItemUrl
                        slug
                    }
                }
            }
            post(id: $databaseId, idType: DATABASE_ID) {
                title
                slug
                newsFields {
                    date
                }
				seoFields {
					metaTitle
					metaDescription
					ogpImage {
						mediaItemUrl
					}
				}
                categories {
                    edges {
                        node {
                            name
                        }
                    }
                }
            }
            posts (first: 500) {
                edges {
                    node {
                        title
                        link
                        slug
                        date
                        content(format: RENDERED)
                        featuredImage {
                            node {
                                mediaItemUrl
                            }
                        }
                        newsFields {
                            date
                        }
                        categories {
                            edges {
                                node {
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

import React from "react"
import NewsItem from 'components/newsItem'

export default ({ nextPost, prevPost }) => {

    return (

        <div className="newsNextPrevPosts">

            <div className="container">

                { prevPost ?

                    <NewsItem item={ prevPost.node } nextPrev="Previous post" />

                : <div /> }

                { nextPost ?

                    <NewsItem item={ nextPost.node } nextPrev="Next post" />

                : <div /> }

            </div>

        </div>

    )
}
